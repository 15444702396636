import React from 'react'
import CareerLayout from '../../components/careerLayout'

export default function GraphicDesigner () {
  return (
    <CareerLayout title='Graphic Designer' location='Remote'>
      <h2>Overview</h2>
      <p>Trexity is looking for an experienced Graphic Designer to help create and maintain a consistent brand aesthetic for an ambitious (and quickly growing) startup.</p>

      <h3>Apply for this position, if</h3>
      <ul className='trx-bullet mb'>
        <li>You are passionate about visual storytelling</li>
        <li>You know the impact beautiful graphics can have and love visual communication</li>
        <li>You are self-driven and take pride in delivering a thoughtful, polished product</li>
        <li>You are eager to contribute meaningful work</li>
        <li>You enjoy creative collaboration.</li>
      </ul>

      <h2>Responsibilities</h2>
      <ul className='trx-bullet mb'>
        <li>Supporting the VP of Marketing and Brand executing our overall brand design goals and shaping our overall aesthetic</li>
        <li>Development of visual style guides and the creation of assets for social media, merchandise, print, and web.</li>
        <li>Creation of daily communication collateral and campaign work</li>
        <li>Redevelopment and updating of the Trexity Website and ongoing daily maintenance</li>
        <li>Take pride in safeguarding and growing the brand, helping to maintain and manage our revitalized go forward strategy </li>
        <li>Facilitate communication within the marketing team, other internal departments and also with our customers</li>
      </ul>

      <h2>Experience and skills</h2>
      <ul className='trx-bullet mb'>
        <li>3 to 5 years experience in the digital design world</li>
        <li>Enjoy working in a fast paced startup environment</li>
        <li>Personable and enjoy working in a team environment</li>
        <li>A creative and innovative thinker, who is goal focused</li>
        <li>Ability to work proactively and autonomously on a daily basis</li>
        <li>Highly proficient in Adobe Creative Cloud</li>
      </ul>

      <h2>Bonus</h2>
      <ul className='trx-bullet mb'>
        <li>Illustration and iconography experience</li>
        <li>Experience with WordPress</li>
        <li>Motion graphic capabilities</li>
      </ul>

      <h2>Benefits</h2>
      <ul className='trx-bullet mb'>
        <li>Equal opportunity employer</li>
        <li>Full health benefits from day one</li>
        <li>3 weeks vacation</li>
        <li>Employee Share Option Plan</li>
        <li>Remote and in office work options</li>
      </ul>
    </CareerLayout>
  )
}
